<!-- follow-contact-result -->
<template>
  <el-select
    class="follow-contact-result"
    v-model="selected"
    :placeholder="placeholder"
    :disabled="disabled"
    :clearable="clearable"
    :filterable="filterable"
    :filter-method="filterMethod"
    :default-first-option="defaultFirstOption"
    :size="size"
    @change="handleValueChange"
    @clear="handleValueClear"
  >
    <el-option
      v-for="item in list"
      :label="item[labelKey]"
      :value="item[valueKey]"
      :key="item[valueKey]"
    />
  </el-select>
</template>

<script>
  import select from '../../mixins/select';
  import dictionaryType from '../../enum/dictionary-type';
  import followContactResult from '../../enum/follow-contact-result';
  import { getDictionaries } from '../../api/sys/dictionary';

  export default {
    name: 'follow-contact-result',
    mixins: [select],
    mounted() {
      this.getContactResults();
    },
    methods: {
      async getContactResults() {
        const params = { type: dictionaryType.enum.contactResult, pageSize: 0 };
        const res = await getDictionaries(params);

        if (!res) return;

        const { data: { list: contactResults = [] } = {} } = res;
        const results = contactResults
          .map(item => {
            const { _id, name } = item;

            return {
              [this.labelKey]: name,
              [this.valueKey]: _id,
            };
          })
          .reverse();

        // 特殊值，便于业务判断，用来标识该联系记录所对应的跟进记录的状态应该更新为'已完成'
        const specialResultValue = followContactResult.enum.ok;
        const specialResultObj = {
          [this.labelKey]: followContactResult.map[specialResultValue],
          [this.valueKey]: specialResultValue,
        };

        this.list = [...results, specialResultObj];
      },
    },
  };
</script>
